// 管理者向けの共通の外枠

<template lang="pug">
el-container

  //- 右側のpaddingがないとbox-shadowが消えてしまう
  el-aside.hidden.md__block.min-h-screen.pr-3(width='200px')
    user-info(
      :name='name',
      @clickLogout='confirmLogout'
    )
    //- サイドメニュー
    ul
      li.mt-6(v-for='item in sideMenuItems')
        side-menu-item(v-bind='item')

  el-container

    //- スマホ向けヘッダ
    el-header.md__hidden.p-2.bg-teal-400(
      height='auto',
      :class='{ "nav-opened": menuVisibility }'
    )
      .flex.items-center.justify-between.text-white
        h1.text-sm.leading-none
          | 名古屋市私立幼稚園協会
          br
          | WEBシステム
      //- メニュー
      div
        //- メニューボタン
        .absolute.top-0.right-0.p-2.block.text-center.text-white.cursor-pointer.hover__bg-teal-600(
          @click='menuVisibility = true'
        )
          fa-icon.text-2xl(icon='bars')
      //- メニュー本体
      nav
        a.m-4.p-1.block.bg-gray-300.text-center(@click='menuVisibility = false') 閉じる
        p.user-info
          | こんにちは、{{ name }} さん
          br
          a(@click='confirmLogout') [ログアウト]
        ul
          li(v-for='item in sideMenuItems')
            router-link(v-if='!isExternalLink(item.to)', :to='{ name: item.to }') {{ item.name }}
            a(
              v-else,
              :href='item.to',
              target='_blank',
              rel='noopener'
            ) {{ item.name }}

    el-main.p-3.md__p-4
      header.md__flex.items-center.justify-between
        h2.py-2.text-center.text-xl {{ title }}
        p.hidden.md__block.text-sm.text-gray-400 名古屋市私立幼稚園協会
      
      main.mt-1.md__mt-2
        router-view
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import UserInfo from '@/components/user-info'
import SideMenuItem from '@/components/side-menu-item.vue'
import sideMenuItemsMap from '@/helpers/side-menu-items-map'

const re = /^https?:\/\//

export default {
  name: 'AdminCommon',

  components: {
    UserInfo,
    SideMenuItem,
  },

  data() {
    return {
      menuVisibility: false,
    }
  },

  computed: {
    ...mapGetters({
      role: 'app/role',
      name: 'app/name',
    }),
    sideMenuItems() {
      return sideMenuItemsMap[this.role]
    },
    // このビューのタイトル
    title() {
      return this.$route.meta.title
    },
  },

  methods: {
    ...mapActions({
      logout: 'app/logout',
    }),
    async confirmLogout() {
      try {
        await this.$confirm('ログアウトしてもよろしいですか？', '確認', {
          confirmButtonText: 'ログアウト',
          cancelButtonText: 'キャンセル',
          type: 'info',
        })
        this.logout()
        this.$router.push({ name: 'PublicLogin' })
      } catch (e) {
        // キャンセルで例外発生なのでなにもしない
      }
    },
    isExternalLink(to) {
      return re.test(to)
    },
  },

  watch: {
    $route() {
      this.menuVisibility = false
    },
  },
}
</script>

<style lang="sass" scoped>
nav
  position: fixed
  right: -320px
  top: 0
  width: 300px
  height: 100vh
  background: lighten($color-1, 40%)
  transition: all .6s
  overflow-y: auto
  z-index: 100
  .user-info
    padding: 0.3rem
    margin: 0 1rem
    font-size: 0.9rem
    text-align: center
    background: $white
    border: 1px solid lighten($color-1, 30%)
  ul
    margin: 0
    padding: 0
    list-style-type: none
    li
      text-align: center
      padding: 0 14px
      a
        display: block
        padding: 8px 0
        border-bottom: 1px solid $white
.nav-opened
  nav
    right: 0
</style>
