// サイドメニュー

<template lang="pug">
router-link.block.p-3.shadow-md.bg-white.bg-opacity-50.text-center.rounded.hover__bg-opacity-100(
  v-if='!isExternalLink',
  :to='{ name: to }'
)
  .text-3xl.text-teal-400
    fa-icon(:icon='icon')
  .text-teal-800 {{ name }}
a.block.p-3.shadow-md.bg-white.bg-opacity-50.text-center.rounded.hover__bg-opacity-100(
  v-else,
  :href='to',
  target='_blank',
  rel='noopener'
)
  .text-3xl.text-teal-400
    fa-icon(:icon='icon')
  .text-teal-800 {{ name }}
</template>

<script>
const re = /^https?:\/\//

export default {
  name: 'SideMenuItem',
  components: {},
  props: {
    // 表示するテキスト
    name: {
      type: String,
      required: true,
    },
    // アイコン
    icon: {
      type: String,
      required: true,
    },
    // リンク先
    to: {
      type: String,
      required: true,
    },
  },
  computed: {
    isExternalLink() {
      return re.test(this.to)
    },
  },
}
</script>

<style lang="sass" scoped>
// 下線を消す
a
  text-decoration: none !important
</style>
