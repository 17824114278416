// ロール毎のメニュー項目のマップ

const admin = [
  {
    name: 'トップページ',
    to: 'AdminTop',
    icon: 'home',
  },
  {
    name: 'メッセージの管理',
    to: 'AdminMessages',
    icon: 'envelope',
  },
  {
    name: '各種様式の管理',
    to: 'AdminDocuments',
    icon: 'file-alt',
  },
  {
    name: '研修の管理',
    to: 'https://member.nagoyashiyo.or.jp/seminar/',
    icon: 'graduation-cap',
  },
  {
    name: 'アンケートの管理',
    to: 'AdminSurveys',
    icon: 'clipboard',
  },
]

const org = [
  {
    name: 'トップページ',
    to: 'MemberTop',
    icon: 'home',
  },
  {
    name: 'メッセージ受信箱',
    to: 'MemberMessages',
    icon: 'envelope',
  },
  {
    name: '書類のダウンロード',
    to: 'MemberDocuments',
    icon: 'file-alt',
  },
  {
    name: '研修等申し込み',
    to: 'https://member.nagoyashiyo.or.jp/seminar/',
    icon: 'graduation-cap',
  },
  {
    name: 'アンケート',
    icon: 'clipboard',
    to: 'MemberSurveys',
  },
  {
    name: '自園情報',
    icon: 'id-card',
    to: 'MemberProfile',
  },
]

const staff = [
  {
    name: 'トップページ',
    to: 'MemberTop',
    icon: 'home',
  },
  {
    name: '研修等申し込み',
    to: 'https://member.nagoyashiyo.or.jp/seminar/',
    icon: 'graduation-cap',
  },
]

export default {
  admin,
  org,
  staff,
}
