// 右上のユーザ情報

<template lang="pug">
.py-4.px-2.bg-teal-500.text-center.text-white.rounded-br
  .text-6xl
    fa-icon(icon='user-circle')
  .mt-3.text-sm こんにちは
  .text-sm {{ name }} さん
  .mt-2
    button.px-3.text-sm.border.border-white.rounded(
      @click='clickLogout'
    ) ログアウト
</template>

<script>
export default {
  name: 'UserInfo',

  props: {
    name: {
      type: String,
      required: true,
    },
  },

  methods: {
    clickLogout() {
      this.$emit('clickLogout')
    },
  },
}
</script>
